// Quasar SCSS (& Sass) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.
// box-shadow: 0px 0px 6px 0px #e8e8e8

$primary: #2f54eb;

$secondary: #26a69a;
$accent: #9c27b0;

$dark: #1d1f22;

$positive: #21ba45;
$negative: #f5222d;
$info: #31ccec;
$warning: #f2c037;
$red: #f5222d;
$gray-light: #ececec;
$orange-9: #ed590b;
// 主题变量开始
$white: #ffffff;
$black: #000000;
$mainBgColor: #292929;
$hoverColor: #454545;
$bgColorEC: #ececec;
$bgColor666: #666666;

$lightColor: #595959;
$darkColor: #d9d9d9;

$tableRow: #494949;
$cancelBtn: #454444;
$open-font-color: #333333;
$toggle-color: #9e9e9e;
$dark-grey2: #444444;
$dark-grey8: #dddddd;
// 主题变量结束
$space-base: 16px !default;
$space-x-base: $space-base !default;
$space-y-base: $space-base !default;

$body-font-size: 12px !default;

// Max width at which point
// current size ends
$breakpoint-xs: 599px !default;
$breakpoint-sm: 1023px !default;
$breakpoint-md: 1439px !default;
$breakpoint-lg: 1919px !default;

$flex-cols: 12 !default;
$flex-gutter-xs: ($space-base * 0.25) !default;
$flex-gutter-sm: ($space-base * 0.5) !default;
$flex-gutter-md: $space-base !default;
$flex-gutter-lg: ($space-base * 1.5) !default;
$flex-gutter-xl: ($space-base * 3) !default;

$body-font-size: 14px !default;
$body-line-height: 1.5 !default;

// sorry for long line; we need .sass and it doesn't support multi-line list
$flex-gutter: (
  'none': 0,
  'xs': $flex-gutter-xs,
  'sm': $flex-gutter-sm,
  'md': $flex-gutter-md,
  'lg': $flex-gutter-lg,
  'xl': $flex-gutter-xl
) !default;

// sorry for long line; we need .sass and it doesn't support multi-line list
$sizes: (
  'xs': 0,
  'sm': (
    $breakpoint-xs + 1
  ),
  'md': (
    $breakpoint-sm + 1
  ),
  'lg': (
    $breakpoint-md + 1
  ),
  'xl': (
    $breakpoint-lg + 1
  )
) !default;

$breakpoint-xs-min: 0 !default;
$breakpoint-xs-max: $breakpoint-xs !default;

$breakpoint-sm-min: map-get($sizes, 'sm') !default;
$breakpoint-sm-max: $breakpoint-sm !default;

$breakpoint-md-min: map-get($sizes, 'md') !default;
$breakpoint-md-max: $breakpoint-md !default;

$breakpoint-lg-min: map-get($sizes, 'lg') !default;
$breakpoint-lg-max: $breakpoint-lg !default;

$breakpoint-xl-min: map-get($sizes, 'xl') !default;
$breakpoint-xl-max: 9999px !default;

$generic-border-radius: 4px !default;
$generic-hover-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1) !default;
$typography-font-family: 'PingFangSC-Regular', 'SourceHanSansCN', 'Microsoft YaHei', 'Roboto', '-apple-system', 'Helvetica Neue', Helvetica,
  Arial, sans-serif !default;
$min-line-height: 1.12 !default;

$space-none: (
  x: 0,
  y: 0
) !default;
$space-xs: (
  x: (
    $space-x-base * 0.25
  ),
  y: (
    $space-y-base * 0.25
  )
) !default;
$space-sm: (
  x: (
    $space-x-base * 0.625
  ),
  y: (
    $space-y-base * 0.625
  )
) !default;
$space-md: (
  x: $space-x-base,
  y: $space-y-base
) !default;
$space-lg: (
  x: (
    $space-x-base * 1.5
  ),
  y: (
    $space-y-base * 1.5
  )
) !default;
$space-xl: (
  x: (
    $space-x-base * 3
  ),
  y: (
    $space-y-base * 3
  )
) !default;

$spaces: (
  'none': $space-none,
  'xs': $space-xs,
  'sm': $space-sm,
  'md': $space-md,
  'lg': $space-lg,
  'xl': $space-xl
) !default;

// Button
$button-font-size: 12px !default;

$input-font-size: 12px !default;
$input-text-color: rgba(0, 0, 0, 0.87) !default;
$input-label-color: rgba(0, 0, 0, 0.6) !default;
$input-autofill-color: inherit !default;

$toolbar-min-height: 42px !default;
$toolbar-padding: 0 10px !default;
$toolbar-inset-size: 58px !default;
$toolbar-title-font-size: 21px !default;
$toolbar-title-font-weight: normal !default;
$toolbar-title-letter-spacing: 0.01em !default;
$toolbar-title-padding: 0 12px !default;

$layout-shadow: 0px 0px 6px 0px #e8e8e8 !default;

// Table
$table-transition: $generic-hover-transition !default;
$table-border-radius: $generic-border-radius !default;
// $table-box-shadow: $shadow-2 !default;

// $table-border-color: $separator-color !default;
$table-hover-background: rgba(0, 0, 0, 0.03) !default;
$table-selected-background: rgba(0, 0, 0, 0.06) !default;

// $table-dark-border-color: $separator-dark-color !default;
$table-dark-hover-background: rgba(255, 255, 255, 0.07) !default;
$table-dark-selected-background: rgba(255, 255, 255, 0.1) !default;
