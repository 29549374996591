// 亮色
.body--light {
  .login,
  .reset,
  .register-wrap {
    background: #fff;
  }
  .bit-container {
    box-shadow: 0px 2px 4px 0px #e4e4e4;
    background-color: $white;
  }
  // 首页
  .dashboard {
    .theme-package-item {
      box-shadow: 0px 1px 8px 0px rgba(212, 212, 212, 0.5);
    }
  }
  // 左侧菜单
  .left-nav-container {
    background: url('~assets/nav-bg.png') bottom left no-repeat $primary !important;
    .q-list {
      // background: url('~assets/logo-bg.svg') no-repeat top left;
    }
  }
  .theme-white {
    background-color: $white;
  }
  .theme-cancel-btn {
    background-color: $bgColorEC;
  }
  .theme-text {
    color: var(--q-primary);
  }
  .theme-user-list {
    .q-item.activeTransfer {
      background: #efefef;
    }
  }

  .theme-text-color {
    color: $black !important;
  }
}

body.body--light {
  color: $lightColor;
  background-color: #f5f5f5;
  .q-dialog__backdrop {
    background-color: rgba(0, 0, 0, 0.4);
  }
  .q-dialog .container {
    background-color: $white;
  }
  .bit-form-before {
    color: $black;
  }
  .theme-sub-sysnotice.text-primary {
    color: $lightColor !important;
  }
  .text-orange-9 {
    color: $orange-9 !important;
  }
  .border-color {
    border-color: rgba(0, 0, 0, 0.12);
  }
  .line-bg {
    background: $bgColor666;
  }
  .q-bar {
    background: url('~assets/bar-bg.png') left center no-repeat;
    background-size: cover;
    height: 40px;
  }
}
