
.version-info {
  background: #f7f7f7;
  box-sizing: border-box;
  padding: 10px;
  ul {
    li {
      padding-left: 10px;
      line-height: 26px;
    }
  }
}
